import React from "react"
import "../styles/privacy.scss"
import Layout from "../components/layout"
import ScrollArrow from "../components/ScrollArrow"
import SEO from "../components/seo"
import JobVacancyFormISC from "../components/job-application-form-identity-security-consultant"

const Identity_Security_Consultant = () => (
  <Layout>
    <ScrollArrow showBelow={450} />

    <SEO title="Identity Security Consultant" />
    <div className="job-vac-section">
      <div className="eng-chal-content">
        <div className="transparent">
          <div className="outer-container">
            <div className="inner-container">
              <div className="column">
                <h1>Identity Security Consultant</h1>
                <h2>Job Vacancy Information and Application Form</h2>
                <h3>Contact us and send us your CV for review.</h3>
                {/* <ButtonB href="/about">Our Story</ButtonB> */}
              </div>
            </div>

            <div className="outer-container">
              <div className="inner-container" style={{ textAlign: "left" }}>
                <div style={{ width: "100%" }}>
                  <h3>Job Title</h3>
                  <div className="aut-div">
                    <p>Identity Security Consultant</p>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <h3>Department</h3>
                  <div className="aut-div">
                    <p>Global Identity Practice</p>
                  </div>
                </div>

                <div style={{ width: "100%" }}>
                  <h3>Location</h3>
                  <div className="aut-div">
                    <p>
                      Flexible - working from home, office or on customer sites.
                    </p>
                  </div>
                </div>

                <div>
                  <h3>Job Description</h3>
                  <div className="aut-div">
                    <p>
                      We are looking to expand our Identity Consulting and
                      Professional Services Team with top-level Subject Matter
                      Experts. If you are passionate about helping organisations
                      secure and manage the challenges around Identity and
                      Privileged Access and want to be part of a team that
                      values your genius, drive and can-do attitude, get in
                      touch with us. We are waiting to speak with you.{" "}
                    </p>
                  </div>

                  <div>
                    <h3>Duties and Responsibilities:</h3>
                    <div className="aut-div">
                      <ul>
                        <li>
                          • Help architect and lead Metsi’s clients, on
                          successful journeys securing and managing identity and
                          access.
                        </li>
                        <li>
                          • Participate in guiding Identity and Access
                          Management enterprise governance processes and drive
                          the adoption of IAM best practises, and solution
                          maturation for sustainable operational futures and
                          long-term value.
                        </li>
                        <li>
                          • Talking to customers and understanding their
                          challenges and needs, guiding them in terms of
                          Industry Standards, and how to achieve their goals in
                          combination with market leading technology solutions.
                        </li>
                        <li>
                          • Use your amazing skills and real-world experience
                          alongside your capacity to learn new technologies and
                          functionalities, to constantly educate your clients
                          and mentor your peers.
                        </li>
                        <li>
                          • Where necessary be creative, ensuring the customer
                          gets the bespoke journey that is right for them,
                          tailored for their organisation.
                        </li>
                        <li>
                          • Confident speaking and evangelism skills are great,
                          but listening is just as key. Demonstrate great
                          communication and consultative characteristics, show
                          the listening and comprehension skills so important in
                          being a trusted adviser.
                        </li>
                        <li>
                          • Manage the successful technical delivery and
                          operational adoption of Information Security projects
                          and services for our customers, working directly with
                          key business stakeholders, executives, and project
                          teams.
                        </li>
                        <li>
                          • Create, review and prioritise technical security
                          roadmaps related to cloud infrastructure, IAM and GRC.
                        </li>
                        <li>
                          • Use your experience and brilliance to develop
                          solutions and recommendations for issues caused by
                          process challenges, emerging threats, technology and
                          organisational changes.
                        </li>
                        <li>
                          • Provide project reporting and documentation
                          essential for smooth project delivery and the
                          all-important successful handover to BAU operations.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <br />
                    <h3>Competencies:</h3>
                    <div className="aut-div">
                      <ul>
                        <li>
                          • Experience in Identity and Access Management /
                          Privilege Access Management solutions
                        </li>
                        <li>
                          • Strong critical thinking and analytical skills and
                          ability to think “out of the box” required.
                        </li>
                        <li>
                          • Ensure high level of systems security compliance and
                          ability to coordinate with, and act as, an authority
                          to resolve incidents by working with other information
                          security specialists to correlate threat assessment
                          data.
                        </li>
                        <li>
                          • Some knowledge of scripting languages (VBScript,
                          PowerShell, Perl, JavaScript, etc.). not all
                          essential, but always a nice to have.
                        </li>
                        <li>
                          • Able to provide a High-Level Designs based on the
                          current state and future state architecture you are
                          providing. (No Crayola Stick Figures Please…Unless
                          they are AMAZING)
                        </li>
                        <li>• Exposure of LDAP/AD, SSH, RDP, HTTP.</li>
                        <li>
                          • Experience in device integration, user-onboarding,
                          password management on PAM. The easy stuff…but ensure
                          it is done right for each specific customer. They are
                          all different
                        </li>
                        <li>
                          • Basic understanding of Database and SQL
                          queries/scripts.
                        </li>
                        <li>• Proven troubleshooting skills.</li>
                        <li>
                          • Proficient in incident response processes. Are you
                          capable of being ready to put out a fire if needed?
                        </li>
                        <li>
                          • Capability to deploy & implement IAM/PAM solutions
                          (SailPoint and Delinea ideally)
                        </li>
                        <li>
                          • Experience in Windows and/or Linux operating
                          systems.
                        </li>
                        <li>
                          • Integrating solutions with various platform such as
                          Windows servers, UNIX servers, Databases, and
                          Networking Devices.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <br />
                    <h3>About Metsi</h3>
                    <div className="aut-div">
                      <p>
                        We are a fast-moving organisation at the forefront of
                        driving digital maturity in this multi-cloud world.
                        End-to-end automation is everything to us, and our
                        Engineering Team is core to building and securing
                        intelligent solutions, that drive innovation and
                        business agility for our customers. With an expanding
                        global presence and an emphasis on employee work-life
                        balance, Metsi provides great opportunity for travel and
                        flexible working arrangements, as well as personal
                        development and growth.
                      </p>
                      <h3>Apply Now:</h3>
                    </div>
                  </div>
                </div>

                <JobVacancyFormISC />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Identity_Security_Consultant
